import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeDishonestyForm from '../../components/game-forms/be-dishonesty-form';
import BeGameInstructions from '../../components/be-game-instructions';
import useLocalStorageState from 'use-local-storage-state'

const DishonestyIndividualPage = (props) => {
  const [experiment, setExperiment] = useLocalStorageState("experiment");
  const [dataUser, setDataUser] = React.useState();
  const handleOnChange = (event) => {
    dataUser[event.target.name] = event.target.value;
    setDataUser({...dataUser});
  };

  React.useEffect(() => {
    setDataUser((props.location.state?.gamePosition !== undefined && experiment?.games[props.location.state.gamePosition].params) || {
        currency: '$',
        value: 150000,
        value_1: 2,
        value_2: 5,
        value_3: 10,
        value_4: 20,
        value_5: 30,
        value_6: 40,
        value_7: 50,
      }
    );
  }, []);

	return(
        <GameCreationRoot title={"Dishonesty (individual) game"}>
          <BeGameInstructions gameId={'dishonestyindividual'} dataUser={dataUser}  />
            <BeDishonestyForm handleOnChange={handleOnChange}  type={'individual'}/>
        </GameCreationRoot>
	);
}

export default DishonestyIndividualPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "form_errors"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;